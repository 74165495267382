var login = (function (me) {

    me.init = function(){
     	if(!$('body').hasClass('route-login')) return;
 
		// $('#login-form-link').click(function(e) {
		// 	e.preventDefault();
		// 	$("#login-form").delay(200).fadeIn(300);
		// 	$("#register-form").fadeOut(200);
		// 	$('#register-form-link').removeClass('active');
		// 	$(this).addClass('active');
		// });
		// $('#register-form-link').click(function(e) {
		// 	e.preventDefault();
		// 	$("#register-form").delay(200).fadeIn(300);
		// 	$("#login-form").fadeOut(200);
		// 	$('#login-form-link').removeClass('active');
		// 	$(this).addClass('active');
		// });




		
		// $('#date_of_birth').datepicker({
	 //        dateFormat: 'dd-mm-yyyy'
	 //    });
  		

  		$('a[href="#tab2"]').click(function(e) {
  			$('#registrationModal').modal('show');
  		});
    };

    return me;
}(login || {}));
