var swiper = (function (me) {

    me.init = function() {
        //if(!$('body').hasClass('swiper')) return;

        $.fn.ajaxSwiper = function (options) {

            var loadMoreBeforeEnd = 4;
            var loading = false;
            var total = null;
            var page = 1;
            var startPage = 1;
            var endPage = 0;
            var mySwiper = null;
            var transitioning = false;
            var loadWaitingSlides = function () {
            };

            var settings = $.extend({
                url: "/jobs/inspired-swiper",
                favourites: null,
                slide: 0,
                perPage: 10
            }, options);


            mySwiper = new Swiper(this, {
                preventClicks: false,
                preventClicksPropagation: false,
                pagination: '.swiper-pagination',
                slidesPerView: 'auto',
                centeredSlides: true,
                paginationClickable: true,
                spaceBetween: 30,
                grabCursor: true,
                effect: 'coverflow',
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                //scrollbar: '.swiper-scrollbar',
                scrollbarHide: true,
                coverflow: {
                    rotate: 30,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true
                },
                onTransitionEnd: onSlideChanged,
                onTransitionStart: onTransitionStart
            });

            function onTransitionStart() {
                transitioning = true;
            }

            function number_format(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            function onSlideChanged(mySwiper) {

                //if transitioning, and the load 'loads' it causes jumps and glitches, so we're gonna wait for the transition to end.
                transitioning = false;
                loadWaitingSlides();
                loadWaitingSlides = function () {
                };

                if (mySwiper.activeIndex >= (mySwiper.slides.length - loadMoreBeforeEnd)) {
                    loadSlides('end');
                }
                if ((mySwiper.activeIndex < loadMoreBeforeEnd) && ((startPage) > 1)) {
                    loadSlides('start');
                }

                //update URL - but check, as if on loading slide, it wont get the ID
                //see the uriAnchor.setAnchor code further down which fires when the loading slide is removed
                // if($('.swiper-slide-active').attr('data-slide-index')){
                // 	$.uriAnchor.setAnchor({
                // 	  slide : $('.swiper-slide-active').attr('data-slide-index')
                // 	},null,true);
                // }

            }

            function addTipToSwipter() {
                var tip = $('.swiper-slide--tips__hidden').first().clone();
                $('.swiper-slide--tips__hidden').first().remove();
                tip.wrap('<div>');
                tip.removeClass('swiper-slide--tips__hidden');
                return tip.parent().html();
            }

            function loadSlides(where) {
                if (where == 'start') {
                    startPage--;
                    page = startPage;
                } else if (where == 'end') {
                    endPage++;
                    page = endPage;
                }

                loading = true;

                if(where !== 'start') {
                    $.ajax({
                        url: settings.url,
                        data: {page: page},
                        headers: {'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')},
                    })
                        .done(function (data) {
                            if (transitioning) {
                                loadWaitingSlides = function () {
                                    addSlidesToSwiper(data, where);
                                };
                            } else {
                                addSlidesToSwiper(data, where);
                            }
                        })
                        .fail(function (request, textStatus, errorThrown) {
                            console.log("error", request.status);
                            if (request.status == 401) { //unauthorized
                                window.location = '/auth/login';
                                return false;
                            }
                        })
                        .always(function (data) {
                            loading = false;//we've loaded
                        });
                }
            }


            function addSlidesToSwiper(data, where) {
                var indexBeforeChange = mySwiper.activeIndex;

                $('.swiper-slide').removeClass('loading-left').removeClass('loading-right');

                //checks if is a favourite
                function isInFavourites(id) {
                    if (!settings.favourites) return false;
                    for (var i = 0; i < settings.favourites.length; i++) {
                        if (id == parseInt(settings.favourites[i])) return true;
                    }
                }

                //generate the slides
                var slides = [];
                for (var i = 0; i < data.data.length; i++) {
                    if ((i % 3) == 0 && i > 0) {
                        var tip = addTipToSwipter();
                        if (typeof tip !==  'undefined') {
                            slides.push(tip);
                        }

                    }
                    var job = data.data[i];

                    //background colour
                    if (job.industries[0] !== undefined) {
                        var background = job.industries[0].background;
                    } else {
                        var background = 'cea84a';
                    }
                    //industry titles
                    var industryTitles = '';
                    for (var ii = 0; ii < job.industries.length; ii++) {
                        industryTitles = industryTitles + job.industries[ii].title + ', ';
                    }
                    industryTitles = industryTitles.replace(/,\s*$/, "");
                    //availability growth
                    if (job.availability == 'High Growth') {
                        job.growthText = 'High Growth';
                        job.growthClass = 'fa-arrow-up highgrowth';
                    } else if (job.availability == 'Low Growth') {
                        job.growthText = 'Low Growth';
                        job.growthClass = 'fa-arrow-up lowgrowth';
                    } else if (job.availability == 'Job of the Future') {
                        job.growthText = 'High Growth';
                        job.extraText = 'Job of the Future';
                        job.growthClass = 'fa-arrow-up highgrowth';
                    } else {
                        job.growthText = 'Declining';
                        job.growthClass = 'fa-arrow-down declining';
                    }

                    //favourites
                    if (isInFavourites(job.id)) {
                        job.favouriteData = 'data-favourite-action="remove"';
                        job.favouriteClass = 'fa-heart';
                    } else {
                        job.favouriteData = 'data-favourite-action="add"';
                        job.favouriteClass = 'fa-heart-o';
                    }

                    job.maximumSalary = job.maximumSalary !== 'Unspecified' ? '£' + job.maximumSalary : job.maximumSalary;

                    job.minimumSalary = job.minimumSalary !== 'Unspecified' ? '£' + job.minimumSalary : job.minimumSalary;

                    if(job.extraText == 'Job of the Future') {
                        job.jobOfTheFuture = true;
                    }

                    var source   = $("#jobs-card-template").html();

                    var template = Handlebars.compile(source);

                    var html = template({job: job, background: background, index: (i + ((page - 1) * 10)), industryTitles: industryTitles});

                    slides.push(html);
                }

                //add the slides to the swiper
                if (where == 'end') {
                    mySwiper.appendSlide(slides);
                } else if (where == 'start') {
                    slides.reverse();//reverse if putting them on start
                    mySwiper.prependSlide(slides);
                }

                //jump back to index incase we landed on the last slide that was removed.
                mySwiper.slideTo(indexBeforeChange, 0, false);

                //remove slides if needed
                removeSlides = [];
                if (where == 'start') {//removing from end
                    for (var i = 0; i < data.per_page; i++) {
                        removeSlides.push(mySwiper.slides.length - i - 1);
                    }
                    endPage--;
                    mySwiper.removeSlide(removeSlides);
                    mySwiper.slideTo(indexBeforeChange + data.per_page, 0, false);
                } else if (where == 'end' && endPage > 2 && endPage < data.last_page) {//removing from start
                    // for (var i = 0; i < data.per_page; i++) {
                    //     removeSlides.push(i);
                    // }
                    // startPage++;
                    // mySwiper.removeSlide(removeSlides);
                    // mySwiper.slideTo(indexBeforeChange - data.per_page, 0, false);
                }

                if (startPage > 1) $(mySwiper.slides[0]).addClass('loading-left');
                if (endPage < data.last_page) $(mySwiper.slides[mySwiper.slides.length - 1]).addClass('loading-right');

            }

            loadSlides('end');
        }
    }
    return me;
}(swiper || {}));