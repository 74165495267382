(function($) {
    if ($('#free').length && $('#free').val() !== '') {
        $("#free").focus(function() { $(this).select(); }).select();
    }
    if ($('.clear-search-filters').length) {
        $('.clear-search-filters').click(function(e) {
            e.preventDefault();

            var form = $('.search-form');

            $('.collapse', form).each(function(){
                var current_sortable = $('.sortable', this);
                var current_select = $('select', this);
                if (current_sortable.length && current_select.length) {
                    $('[data-remove]', current_sortable).each(function(){
                        var parent = $(this).parent();
                        //add back to select list
                        var text = $(parent).text().trim();
                        var id = $(parent).attr('data-item-id');
                        $(current_select).append('<option value="'+id+'">'+text+'</option>');
                        reorder($(current_select));
                        $(current_select).selectpicker('refresh');
                        //remove
                        $(parent).remove();
                    });

                }
            })
        });
        function reorder(select) {
            var options = $('option:not(.sticky)', select);
            var arr = options.map(function(_, o) { return { t: $(o).text(), v: o.value }; }).get();
            arr.sort(function(o1, o2) {
                //if(o1.t=='Select an A-Level...') return false;
                var t1 = o1.t.toLowerCase(), t2 = o2.t.toLowerCase();
                return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
            });
            options.each(function(i, o) {
                //if(o.value=='') return false;
                o.value = arr[i].v;
                $(o).text(arr[i].t);
            });
        }

    }
})(jQuery);

