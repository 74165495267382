var feedback = (function (me) {
    me.init = function(){
        if ($('.feedback-modal').length ) {
            $('input[name="rating"]').change(function(){
                var select = $('#rating-select');
                var current_score = $(this).val();
                $('option:selected', current_score).removeAttr('selected');
                $('option[value="' + current_score + '"]', select).attr('selected', 'selected');
         
                $('#submit-rating-form', '.feedback-modal').removeAttr('disabled');
            });

            $('#submit-rating-form', '.feedback-modal').attr('disabled', 'disabled');

            if ($('.feedback-modal').data('show-modal') === true) {
                setTimeout(function () {
                    $('.feedback-modal').modal('show');
                }, 3000);
            }
        }

        $('#submit-rating-form').click(function(e) {
            e.preventDefault();
            $("#rating-form").trigger('submit');
        });

        $('#close-rating-form').click(function(e) {
            e.preventDefault();
            var button = $(this);
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                }
            });

            $.ajax({
                type: "GET",
                url: button.attr('href'),
                success: function(data) {
                    if (data.success) {
                        $('.feedback-modal').modal('hide');
                    }
                }
            });
        });

        $("#rating-form").submit(function(e) {
            e.preventDefault();
            var form = $(this);

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                }
            });

            $.ajax({
               type: "POST",
               url: form.data('url'),
               data: form.serialize(),
               success: function(data) {
                   if (data.success) {
                       $('.feedback-modal .modal-footer').hide();
                       $('.rating-content').fadeOut(function(){
                           $('.rating-success').fadeIn();
                           setTimeout(function(){
                               $('.feedback-modal').modal('hide');
                           }, 1000);
                       })

                   }

               }
            });

        });
    };

    return me;
}(feedback || {}));
