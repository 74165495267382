var index = (function (me) {

    me.init = function(){
    	if(!$('body').hasClass('route-root')) return;

    	var jobs = [
            'Hospital doctor',
            'Digital currency advisor',
            'Astronaut',
            'Medical imaging software engineer',
            'Nurse',
            'IoT (Internet of Things) data creative',
            'Architect',
            'Sustainable power innovator',
            'Radiographer',
            'Big data wrangler',
            'Art gallery curator',
            'Augmented reality architect',
            'Gamification designer',
            'Drone pilot',
            'Head teacher',
            'Virtual habitat designer',
            'Veterinary nurse',
            'Wearable technology therapist',
            'Dancer',
            'Healthcare navigator',
            'Primary school teacher',
            'Carbon capture technician',
            'Airline pilot',
            'Gamification designer',
            'Actor',
            'Online community manager',
            'Medical physicist',
            'Digital marketing officer',
            'Paleontologist',
            'Marketing e-Mediamaker',
            'Economist',
            'Video editor',
            'Psychologist',
            'Computer games developer',
            'Forensic scientist',
            'Social media manager',
            'Broadcast engineer',
            'Autonomous vehicles engineer',
            'Design and development engineer',
            'IT project manager',
            'Police officer',
            'Business analyst',
            'Geoscientist',
            'Graphic designer',
            'Market research specialist',
            'SmartCube technician',
            'Finance and investment analyst',
    	];

    	var colors = [
    		'#85d9a8',
    		'#e18aa5',
    		'#e1d074',
    		'#9f89de',
    		'#89c7de',
    		'#89c7de'
    	];

    	$(".typed").typed({
            strings: jobs,
            typeSpeed: 0,
            loop: true,
            shuffle: true,
            backDelay: 2000,
        });

        var footerHeight = $('footer').outerHeight();
        $('#how_it_works').css('margin-bottom',footerHeight);
    };

    return me;
}(index || {}));
