(function($) {
    $('.cookie-notice span').on('click', function(){
            jqxhr = $.ajax({
                url: '/cookie/accept',
                type: 'GET',
                headers: {'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')}
            }) .done(function(data) {
                $('.cookie-notice').fadeOut();
            });
    });
})(jQuery);

