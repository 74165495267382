var favourites = (function (me) {
	var Sortable;

    me.init = function(){
     	if(!$('body').hasClass('route-favourites')) return;
   	


		//make the list sortable
		Sortable = $("[data-favourites-sortable]").sortable({
				//containment: "parent",
				handle: '.favourite-grab',
				axis: "y",
				helper: "clone",
				placeholder: 'favourite placeholder',
				revert: true,
				animation: 100,
				cancel: "a,p",
				update: function(event, ui){
					onOrderChange(event, ui);	
				}
		}).disableSelection();



		//remove a favourite on sortable

		var jqxhr = {abort: function () {}};

	    $('body').on('click','[data-remove-favourite]',function(e) {
	    	e.preventDefault();

	    	var favourite = $(this).closest('[data-favourite]');
	    	favourite.css('opacity',0.5);
	    	favourite.find('[data-remove-favourite] i').removeClass('fa-times-circle').addClass('fa-refresh fa-spin');


	    	var id = favourite.attr('data-favourite-job-id');
	    	if(!parseInt(id)) return false;

			//jqxhr.abort();
	    	jqxhr = $.ajax({
	    		url: '/api/favourites/remove/'+id,
	    	})
	    	.done(function(data) {
	    		//console.log("success",data);
	    		
	    		$('[data-favourite]').each(function(index, el) {
	    			if($(el).attr('data-favourite-job-id')==id){
	    				$(el).remove();
	    				$("[data-favourites-sortable]").sortable('refresh');
	    			}
	    		});
	    		//console.log($('[data-favourite]').length);
	    		if($('[data-favourite]').length<=0){
	    			$('[data-no-favourites]').removeAttr('data-remove-favourite').removeClass('hidden').css('opacity',0).fadeTo(500,1);
	    		}
	    		header.updateFavouritesBadge();

	    	})
	    	.fail(function(data) {
	    		//console.log("error",data);
	    	})
	    	.always(function(data) {
	    		//console.log("complete",data);
	    	});

	    });

	    
	    //remove all favourites

	    $('body').on('click','[data-remove-all-favourites]',function(e) {
	    	e.preventDefault();
	    	var favourite = $('[data-favourite]');
	    	favourite.css('opacity',0.5);
	    	favourite.find('[data-remove-favourite] i').removeClass('fa-times-circle').addClass('fa-refresh fa-spin');

	    	jqxhr = $.ajax({
	    		url: '/api/favourites/remove/all',
	    	})
	    	.done(function(data) {
	    		$('[data-favourite]').remove();
	    		$('[data-no-favourites]').removeAttr('data-remove-favourite').removeClass('hidden').css('opacity',0).fadeTo(500,1);
	    		header.updateFavouritesBadge();
	    		$('[data-remove-favourites-button]').addClass('hidden');
	    		$('[data-show-suggested-a-levels-button]').addClass('hidden');
	    	})
	    	.fail(function(data) {
	    		//console.log("error",data);
	    	})
	    	.always(function(data) {
	    		//console.log("complete",data);
	    	});

	    });



	    //ordering
	    function onOrderChange(event, ui){
	    	//console.log('event',event);
	    	//console.log('ui',ui);

	    	$('[data-favourites-container-loading-overlay]').show();

	    	var data = [];

    		$('[data-favourite]').each(function(index, el) {
    			var id = $(el).attr('data-favourite-job-id');
    			data.push(id);
    		});

	    	jqxhr = $.ajax({
	    		url: '/api/favourites/order',
	    		type: 'POST',
	    		data: {data: JSON.stringify(data)},
	    		headers: {'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')}
	    	})
	    	.done(function(data) {
	    		
	    	})
	    	.fail(function(data) {
	    		console.log("error",data);
	    	})
	    	.always(function(data) {
		    	$('[data-favourites-container-loading-overlay]').fadeOut('fast');
	    	});
    	

	    }


    };

    return me;
}(favourites || {}));
