(function($) {
if ($('.school-filters').length) {

        $('.select-picker').selectpicker({
          style: 'btn-primary',
          size: 6,
          actionsBox: true
        });

        $('select[name="school_sort"]').on('change', function(){
            var val = $(this).val();

            $('.search-form').submit();
        });


        $('.clear-school-finder-search-filters').on('click', function(e) {
            e.preventDefault();

            var form = $('.search-form');

            $('.select-picker', form).selectpicker('deselectAll');
            $('.select_stem_entry_rate').selectpicker('val', 0);
            $('#stem_aab_rate').selectpicker('val', 0);
        });
    }
})(jQuery);