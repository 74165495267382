var swiperOld = (function (me) {

    me.init = function(){
     	if(!$('body').hasClass('swiper')) return;





    };

    return me;
}(swiperOld || {}));




(function ( $ ) {
 
    $.fn.ajaxSwiperOld = function( options ) {
 
        var settings = $.extend({
            url: "/jobs/inspired-swiper",
            favourites: null,
            slide: 0
        }, options );
 
        // return this.css({
        // });
 

		Object.size = function(obj) {
		    var size = 0, key;
		    for (key in obj) {
		        if (obj.hasOwnProperty(key)) size++;
		    }
		    return size;
		};

		function number_format(x) {
		    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}

	    var mySwiper = new Swiper(this, {
	    	preventClicks: false,
			preventClicksPropagation: false,
	        pagination: '.swiper-pagination',
	        slidesPerView: 'auto',
	        centeredSlides: true,
	        paginationClickable: true,
	        spaceBetween: 30,
	        grabCursor: true,
	        effect: 'coverflow' ,
	        nextButton: '.swiper-button-next',
	        prevButton: '.swiper-button-prev',
	        //scrollbar: '.swiper-scrollbar',
        	scrollbarHide: true,
        	coverflow: {
	            rotate: 30,
	            stretch: 0,
	            depth: 100,
	            modifier: 1,
	            slideShadows : true
	        },
	        onTransitionEnd: onSlideChanged
	        //onTransitionStart: onSlideChanged
	    });

		function lockSwiper(){
			mySwiper.lockSwipeToNext();
			mySwiper.lockSwipeToPrev();
			mySwiper.disableMousewheelControl();
			mySwiper.disableKeyboardControl();
			mySwiper.disableTouchControl();
		}

		function unlockSwiper(){
			mySwiper.unlockSwipeToNext();
			mySwiper.unlockSwipeToPrev();
			mySwiper.enableMousewheelControl();
			mySwiper.enableKeyboardControl();
			mySwiper.enableTouchControl();
		}

		var loadingSlide = '<div class="swiper-slide jobrow loading"> <div class="indicator"> <div class="loader loader--style1" title="0"> <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve"> <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/> <path fill="#009fe3" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z"> <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite"/> </path> </svg> </div> <span>Loading more...</span></div></div>';

	    var startPage = 0;//to store the pagination to send to controller for the start of slides
	    var endPage = 1;//to store the pagination to send to controller for the end of slides
	    var loadMoreBeforeEnd = 4;//number of slides before end or start to start loading more. ideally needs to be less than the amount of visable cards to the left or right of the active one so it's less noticable.
	    var loading = false;//just so we can stop anything happening while waiting for data.(TODO: look at cancelling the ajax request)
	    var page;
	    var total = null;
	    var perPage = null;

	    function onSlideChanged(mySwiper){
	    	//if it gets 'loadMoreBeforeEnd' amount of slides from the end, load
	    	if(mySwiper.activeIndex >= (mySwiper.slides.length-loadMoreBeforeEnd)) {
	    		loadSlides('end');
	    	}
	    	//if it gets 'loadMoreBeforeEnd' amount of slides from the beginning, load
	    	if(mySwiper.activeIndex < (loadMoreBeforeEnd) && (startPage>0)) {
	    		loadSlides('start');
	    	}

	    	//update URL - but check, as if on loading slide, it wont get the ID
	    	//see the uriAnchor.setAnchor code further down which fires when the loading slide is removed
	    	if($('.swiper-slide-active').attr('data-slide-index')){
				$.uriAnchor.setAnchor({
				  slide : $('.swiper-slide-active').attr('data-slide-index')
				},null,true);
			}

	    }

     	function loadSlides(where){

	     	//catch end of records for next time round ajax
	    	//console.log('calling ajax - total: '+total+', endPage:'+endPage+', perPage:'+perPage);
	    	if(where=='end' && perPage!=null){
	    		if( (endPage*perPage) > total ) {
	    			console.log('cancelling loading');
	    			return false;
	    		}
	    	}
	    	

     		
     		if(loading) return;//if we're still waiting for loading, exit
     		loading = true;//we're now loading

			//lockSwiper();//lock the swiper so user doesn't spam it

			//decide what paging number to use, the start or end one
	    	if(where=='end'){
	    		page = endPage;
	    	} else if(where=='start'){
	    		page = startPage;
	    	}


	    	//call the server
	     	$.ajax({
	     		url: settings.url,
	     		data: {page: page},
	     		headers: {'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')},
	     	})
	     	.done(function(data) {
	     		//unlockSwiper();//unlock here, if it's locked when addSlidesToSwiper runs, nothing is calculated
	     		try { 
	     			total = data.total;
	     			perPage = data.per_page;
	     		} catch(error) {
	     			console.log('error getting pagination data');
	     		}

	     		addSlidesToSwiper(data,where);
	     	})
	     	.fail(function(data) {
	     		console.log("error");
	     	})
	     	.always(function(data) {
				loading = false;//we've loaded
	     	});
     	}

	    function addSlidesToSwiper(data,where){
	    	//console.log('about to add data to slides',data);

	    	//console.log('total',total);
	    	//console.log('endPage',endPage);

 			// if(data.data.length<=0){
 			// 	//no more results, stop
 			// 	//TODO. just dont show the loading slides at all if there are more.
 			// 	if($('.swiper-slide.loading_end').length!=0){
	 		// 		$('.swiper-slide.loading_end .indicator span').text('No more to load!');
	 		// 		$('.swiper-slide.loading_end .indicator .loader').fadeTo(500,0);
	 		// 		$('.swiper-slide.loading_end').delay(1000).fadeTo(500,0, function(){

				// 		mySwiper.removeSlide(mySwiper.slides.length-1);
	 		// 			mySwiper.update();

	 		// 			// mySwiper.slideTo(mySwiper.slides.length-2,200,function(){
				// 			// mySwiper.removeSlide(mySwiper.slides.length-1);
	 		// 			// 	mySwiper.update();
	 		// 			// });
	 		// 		});
 			// 	}
 			// 	return false;
 			// }

	    	//store some needed values before removing slides and adding slides
	    	var activeIndexBeforeChange = mySwiper.activeIndex;
	    	var slidesLengthBeforeChange = mySwiper.slides.length;

 			//remove the loading indicator slides from the beginning and end
 			mySwiper.removeSlide(mySwiper.slides.length-1);
 			if(startPage>0) mySwiper.removeSlide(0);

 			function isInFavourites(id){
 				if(!settings.favourites) return false;
 				for (var i = 0; i < settings.favourites.length; i++) {
 					if(id == parseInt(settings.favourites[i])) return true;
 				}
 			}
			
			//console.log('found '+Object.keys(data.data).length+' slides', data.data);
 			//loop through data and add slides




	    	for (var j = 0; j < data.data.length; j++) {
	    		//reverse the index if it's prepending slides to the start.
	    		var i = j;
		    	if(where=='end'){
		    		i = j;
		    	} else if(where=='start'){
		    		i = ((data.data.length-1) - j);
		    	}

		    	var job = data.data[j];
		    	//background colour
		    	if(job.industries[0]!==undefined){
		    		var background = job.industries[0].background;
		    	} else {
		    		var background = 'cea84a';
		    	}
		    	//industry titles
		    	var industryTitles = '';
		    	for (var ii = 0; ii < job.industries.length; ii++) {
		    		industryTitles = industryTitles + job.industries[ii].title + ', ';
		    	}
		    	industryTitles = industryTitles.replace(/,\s*$/, "");
		    	//availability growth
		    	if(job.availability=='High Growth') {
		    		job.growthText = 'High Growth';
		    		job.growthClass = 'fa-arrow-up highgrowth';
		    	} else if(job.availability=='Low Growth') {
		    		job.growthText = 'Low Growth';
		    		job.growthClass = 'fa-arrow-up lowgrowth';
		    	} else {
		    		job.growthText = 'Declining';
		    		job.growthClass = 'fa-arrow-down declining';
		    	}
		    	//min salary
		    	if(job.starting_rate_min){
		    		job.salaryMin = job.starting_rate_min;
		    	} else if(job.experienced_rate_min){
		    		job.salaryMin = job.experienced_rate_min;
		    	} else if(job.starting_salary_min){
		    		job.salaryMin = job.starting_salary_min;
		    	} else {
		    		job.salaryMin = job.experienced_salary_min;
		    	}
		    	//max salary
		    	if(job.starting_rate_max){
		    		job.salaryMax = job.starting_rate_max;
		    	} else if(job.experienced_rate_max){
		    		job.salaryMax = job.experienced_rate_max;
		    	} else if(job.starting_salary_max){
		    		job.salaryMax = job.starting_salary_max;
		    	} else {
		    		job.salaryMax = job.experienced_salary_max;
		    	}


		    	//favourites
				if(isInFavourites(job.id)) {
					//job.favouriteUri = '/favourites/remove/'+job.id;
					job.favouriteData = 'data-favourite-action="remove"';
					//job.favouriteData = 'data-remove-favourite="'+job.id+'"';
					job.favouriteClass = 'fa-heart';
				} else {
					//job.favouriteUri = '/favourites/add/'+job.id;
					job.favouriteData = 'data-favourite-action="add"';
					//job.favouriteData = 'data-add-favourite="'+job.id+'"';
					job.favouriteClass = 'fa-heart-o';
				}


				//the slide template
				//numbering after h3 tag for title: '+(i+((page-1)*10))+'-
			    var slide = '<div class="swiper-slide jobrow wow fadeIn" data-slide-index="'+(i+((page-1)*10))+'" data-job-row data-id="'+job.id+'"> <div class="joblist_thumbnail_header" style="background:#'+background+';"> <div class="joblist_thumbnail"> <img src="/images/industries/png/clipboard.png" alt=""> </div> <div class="summary"> <span class="availability" style="color:#'+background+';"> <i class="fa '+job.growthClass+'" style="color:#'+background+';"></i> '+job.growthText+' </span> <p>Salary Expectation:</p> <p>&pound;'+number_format(job.salaryMin)+' - &pound;'+number_format(job.salaryMax)+'</p> </div> <div class="clearfix"></div> </div> <div class="joblist_content"> <div class="jobrow_header"> <a href="/job/'+job.slug+'" title="'+job.title+'"><h3>'+job.title+'</h3></a> </div> <div class="industries"> <span class="industry">'+industryTitles+'</span> </div> <div class="description"> <p>'+job.excerpt+'</p> </div> <div class="swiper_footer"> <a href="/job/'+job.slug+'" class="btn btn-primary">More Information...</a> <a href="#" data-toggle="modal" data-target="#shareModal" class="btn btn-primary-white pull-right"><i class="fa fa-share-alt"></i></a> <a href="#" '+job.favouriteData+' class="btn btn-primary-white pull-right"><i class="fa '+job.favouriteClass+'"></i></a> </div> </div> </div>';


			    //console.log('adding slide to the '+where, slide);

			    //add the slides
		    	if(where=='end'){
		    		mySwiper.appendSlide(slide);
		    	} else if(where=='start'){
		    		console.log('start');
		    		mySwiper.prependSlide(slide);
		    	}
	    	}

	    	if(where=='end'){
	    		//remove same amount of slides at the start, once past page 2 to always keep no more than 2x page
		    	if(endPage>2){
		    		for (var i = 0; i < data.per_page; i++) {
			    		mySwiper.removeSlide(0);
			    	}
			    	startPage ++;
		    	}
		    	//if landing on the last loading slide, this gets removed, so active slide reverts to last one, this nudges it back again
		    	if((activeIndexBeforeChange == (slidesLengthBeforeChange-1))) {
		    		mySwiper.slideNext(false, 0);
		    		//anchor stuff tried to get the id but its the loading slide, so get it again.
					$.uriAnchor.setAnchor({
					  slide : $('.swiper-slide-active').attr('data-slide-index')
					},null,true);
		    	}
	    		endPage ++;//increment the 'end' page
			} else if(where=='start') {
	    		//remove same amount of slides at the end, to always keep no more than 2x page
	    		for (var i = 0; i < data.per_page; i++) {
		    		mySwiper.removeSlide(mySwiper.slides.length-1);
		    	}
		    	endPage --;
		    	//if landing on the first loading slide, this gets removed, so active slide reverts to next one, this nudges it back again
		    	if(activeIndexBeforeChange==0)  {
		    		mySwiper.slidePrev(false, 0);
		    		//anchor stuff tried to get the id but its the loading slide, so get it again.
					$.uriAnchor.setAnchor({
					  slide : $('.swiper-slide-active').attr('data-slide-index')
					},null,true);		    		
		    	}
	    		startPage --;//increment the 'start' page
			}

	    	//console.log('added pages - total: '+total+', endPage:'+endPage+', perPage:'+perPage);
	    	//console.log('-----------------------------------------------------------');

	     	//catch end of records and return
	    	// TODO - when going backwards, endPage will still stay on the last number unless it's gone past 2 sets, 
	    	// so this bit still kicks in and doesn't add the end loading slide. :(
	    	// just need to do a calc taking into account the startPage. 
    		if( (endPage*perPage) >= (total)) {
    			console.log('no more to load after this loop');
    		} else {
					//add the loading indicator slides to the end
					mySwiper.appendSlide($(loadingSlide).addClass('loading_end'));
    		}

 			//add the loading indicator slides to the beginning 
 			if(startPage>0) mySwiper.prependSlide($(loadingSlide).addClass('loading_start'));
 			//update/refresh
 			mySwiper.update();
 			firstLoad = false;
	    }



	    //---- handle pre loading of slides
	    firstLoad = true;
	    if(settings.slide>0) {
	    	endPage = Math.floor(settings.slide/10)+1;
	    	endPage > 0 ? startPage = endPage - 1 : startPage = 0;
			loadSlides('end');
		} else {
     		loadSlides('end');//run at start
		}


    };
 
}( jQuery ));