var jobs = (function (me) {

    me.init = function(){
     	if(!$('body').hasClass('route-jobs')) return;


     	$('#shareModal').on('show.bs.modal', function (event) {
		  	var facebook_url = $(event.relatedTarget).attr('data-facebook-url');
		  	var twitter_url = $(event.relatedTarget).attr('data-twitter-url');

		  	$(event.target).find('.facebook-share').attr('href', facebook_url);
		  	$(event.target).find('.twitter-share').attr('href', twitter_url);
		});

	    //filter expand
	    var filterExpanded = false;
		$( "[data-filter-panel]" ).click(function(e) {
			e.preventDefault();

			var height, easing, addClass, bodyClass;

			if(!filterExpanded) {
				// OPEN
				height = $('[data-filter-menu-content]').get(0).scrollHeight;
				filterExpanded = true;
				$('.filter_menu').addClass('open');
				
				$( "[data-filter-menu-content]" ).stop().animate({
						height: height
					}, {
						duration: 500,
						specialEasing: {
						height: 'easeInOutQuart'
					},
					complete: function() {
						$('[data-filter-menu-content]').css('height', 'auto');
						$('[data-filter-menu-content]').css('overflow', 'visible');
					}
				});

			} else {
				//CLOSE
				$('.filter_menu').removeClass('open');
				filterExpanded = false;
				$('[data-filter-menu-content]').css('overflow', 'hidden');
				$( "[data-filter-menu-content]" ).stop().animate({
						height: '0px'
					}, {
						duration: 500,
						specialEasing: {
						height: 'easeInOutQuart'
					},
					complete: function() {
					}
				});
			}
		  

		});

		var maxAlevelCount = 5;

		//make a nice select dropdown
		$('.select-picker').selectpicker({
		  style: 'btn-primary',
		  size: 6
		});

        //select change add to sortable
        $('body').on('change', '.select-picker', function(e) {
            e.preventDefault();
            var sortable_div = $(this).data('sortable-id');

            if($('#' + sortable_div + ' div').length >= maxAlevelCount) {
                $('#' + $(this).data('error-id')).fadeIn();
                return;
            }

            var selectpicker = $(this);

            var input_name = $(this).data('input-name');

            var sortable = $("#" + sortable_div);

            var id = $(this).selectpicker('val');

            if (id=='' || id == undefined || typeof id === 'object') {
                return;
            }

            if (!id) {
            	return;
            }

            //console.log(id);
            var text = $("option[value='"+id+"']", selectpicker).text().trim();

            //remove from select list
            $(this).find('[value='+id+']').remove();
            //reorder();
            $(this).selectpicker('refresh');
            //add to sortable
            var div = $('<div class="alevel" data-item-id="'+id+'"><input type="hidden" name="' + input_name + '[]" value="'+id+'"><i class="glyphicon glyphicon-option-vertical"></i> '+text+' <a href="#" data-remove="true"><i class="fa fa-times-circle pull-right"></i></a></div>');

            div.appendTo(sortable).hide().fadeIn();
            sortable.sortable( "refresh" );
            checkempty();
        });

		//user native dropdown selection if any
		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
			$('.select-picker').selectpicker('mobile');
		}

		//make the list sortable
		$(".sortable").sortable({
				//containment: "parent",
				axis: "y",
				helper: "clone",
				placeholder: 'alevel placeholder',
				revert: true,
				animation: 100,
				cancel: "a,p",
				update: function(event, ui){
					//serializeAlevels();	
				}
		}).disableSelection();

		//remove from sortable
		$('.sortable').on('click','[data-remove]',function(e) {
			e.preventDefault();
			var current_sortable = $(this).closest('.sortable');
			var current_select = current_sortable.data('select-id');

			$(this).parent().stop().animate({opacity: 0}, {duration: 100,
				complete: function() {
					$(this).stop().animate({height: 0}, {duration: 100,
						complete: function() {
							//add back to select list
							var text = $(this).text().trim();
							var id = $(this).attr('data-item-id');
							$('select#' + current_select).append('<option value="'+id+'">'+text+'</option>');
							reorder($('select#' + current_select));
							$('select#' + current_select).selectpicker('refresh');
							//remove
							$(this).remove();
							checkempty(current_sortable);
						}
					});
				}
			});
		});

		function reorder(select) {
			var options = $('option:not(.sticky)', select);
			var arr = options.map(function(_, o) { return { t: $(o).text(), v: o.value }; }).get();
			arr.sort(function(o1, o2) {
			  //if(o1.t=='Select an A-Level...') return false;
			  var t1 = o1.t.toLowerCase(), t2 = o2.t.toLowerCase();
			  return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
			});
			options.each(function(i, o) {
			  //if(o.value=='') return false;
			  o.value = arr[i].v;
			  $(o).text(arr[i].t);
			});
		}

		function checkempty(sortable_div){
			if($('div', sortable_div).length) {
				$("p", sortable_div).hide();
			} else {
				$("p", sortable_div).fadeIn();
			}
		}

		//form validation
		$( "#a_level_form" ).submit(function( e ) {
			if($('#alevels_sortable div').length){
				return true;
			} else {
				$('.validation_error').fadeIn();
				e.preventDefault();
				return false;
			}
		});

		//close form validation notice
		$('.validation_error .close, .alevel_count_error .close').click(function(e) {
			e.preventDefault();
			$(this).parent().fadeOut();
		});

		//number selector
		$('body').on('click', '.btn-number', function(e){
		    e.preventDefault();
		    
		    fieldName = $(this).attr('data-field');
		    type      = $(this).attr('data-type');
		    var input = $(this).parent().parent().find("input[name='"+fieldName+"']");
		    var currentVal = parseInt(unformatted(input.val()));
		    //console.log('currentVal',currentVal);
		    if (!isNaN(currentVal)) {
		        if(type == 'minus') {
		            
		            if(currentVal > input.attr('min')) {
		                input.val(formatted(currentVal - 5000)).change();
		            } 
		            if(parseInt(input.val()) == input.attr('min')) {
		                $(this).attr('disabled', true);
		            }

		        } else if(type == 'plus') {

		            if(currentVal < input.attr('max')) {
		                input.val(formatted(currentVal + 5000)).change();
		            }
		            if(parseInt(input.val()) == input.attr('max')) {
		                $(this).attr('disabled', true);
		            }

		        }
		    } else {
		        input.val(0);
		    }
		});
		$('.input-number').focusin(function(){
		   $(this).data('oldValue', $(this).val());
		});

		$('.input-number').change(function() {
		    
		    minValue =  parseInt($(this).attr('min'));
		    maxValue =  parseInt($(this).attr('max'));
		    valueCurrent = parseInt(unformatted($(this).val()));
		    
		    name = $(this).attr('name');
		    if(valueCurrent >= minValue) {
		        $(".btn-number[data-type='minus'][data-field='"+name+"']").removeAttr('disabled')
		    } else {
		        //alert('Sorry, the minimum value was reached');
		        $(this).val(formatted($(this).data('oldValue')));
		    }
		    if(valueCurrent <= maxValue) {
		        $(".btn-number[data-type='plus'][data-field='"+name+"']").removeAttr('disabled')
		    } else {
		        //alert('Sorry, the maximum value was reached');
		        $(this).val(formatted($(this).data('oldValue')));
		    }
		});

		$(".input-number").keydown(function (e) {
		    // Allow: backspace, delete, tab, escape, enter and .
		    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
		         // Allow: Ctrl+A
		        (e.keyCode == 65 && e.ctrlKey === true) || 
		         // Allow: home, end, left, right
		        (e.keyCode >= 35 && e.keyCode <= 39)) {
		             // let it happen, don't do anything
		             return;
		    }
		    // Ensure that it is a number and stop the keypress
		    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
		        e.preventDefault();
		    }
		});

		function formatted(value){
			//e.replace('£','');
			//e.replace(',','');
			var num = parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
			num = num.replace('.00','');
			return '£'+num;
		}

		function unformatted(value){
			value = value.replace('£','');
			value = value.replace(',','');
			value = value.replace('.00','');
			return parseInt(value);
		}

		//
		// add / remove favourites
		//
		var jqxhr = {abort: function () {}};

	    $('body').on('click','[data-favourite-action]',function(e) {
	    	e.preventDefault();
	    	var action = $(this).attr('data-favourite-action');
	    	var id = $(this).closest('[data-job-row]').attr('data-id');
	    	if(action=='add'){
	    		doAction('add',id,$(this));
	    	} else if(action=='remove'){
	    		doAction('remove',id,$(this));
	    	}
	    });

	    var isFavouriteClass = 'fa-heart';
	    var notFavouriteClass = 'fa-heart-o';
	    var loadingClass = 'fa-refresh fa-spin';
    	var oldClass, newClass, newAction;

	    function doAction(type,id,element){
	    	if(!parseInt(id)) return false;
	    	//show loading indicator
	    	element.removeAttr('data-favourite-action').children('span').css('opacity',0.25);
	    	element.children('i').removeClass(isFavouriteClass).removeClass(notFavouriteClass).addClass(loadingClass);
	    	//do ajax
			//jqxhr.abort();
	    	jqxhr = $.ajax({
	    		url: '/api/favourites/'+type+'/'+id,
	    	})
	    	.done(function(data) {
	    		//console.log("success",data);
	    		if(data.status=='success'){
	    			updateFavouriteIcon(type,element);
	    		} else {
	    			if(type == 'add') type='remove';//if error removing, prob already removed, make it addable
	    			updateFavouriteIcon(type,element);
	    		}
	    	})
	    	.fail(function(data) {
	    		//console.log("error",data);
	    	})
	    	.always(function(data) {
	    		//console.log("complete",data);
	    	});
	    }

	    //callback - change to add / remove
	    function updateFavouriteIcon(type,element){
	    	if(type=='remove'){
	    		oldClass = isFavouriteClass;
	    		newClass = notFavouriteClass;
	    		newAction = 'add';
	    		newText = 'add';
	    	} else {
	    		oldClass = notFavouriteClass;
	    		newClass = isFavouriteClass;
	    		newAction = 'remove';
	    		newText = 'remove';
	    	}
	    	element.attr('data-favourite-action',newAction).children('span').text(newText).css('opacity',1);
	    	element.children('i').removeClass(loadingClass).addClass(newClass);

	    	header.updateFavouritesBadge();
	    }

	};

    return me;
}(jobs || {}));
