(function($) {
     infoBubble = setMarker = previousMarker =  false;

    if ($('#school_search').length) {
       var form = $('#school_search').closest('form');
       form.on('submit', function(e){
           e.preventDefault();

           var postcode = $('#school_search', this).val();
           var request = $.ajax({
                method: "GET",
                url: "https://maps.googleapis.com/maps/api/geocode/json?address=" + postcode + ",+UK&sensor=false&key=AIzaSyBR1ejj3TJmuvamNkEJzDDL44oPZ128yfI",

           });

           request.success(function(item) {
               if (item.status == "OK") {
                   $('#form_lat', form).val(item.results[0].geometry.location.lat);
                   $('#form_lng', form).val(item.results[0].geometry.location.lng);

                   form.unbind('submit');
                   form.submit();
               } else {

                   var p = $('<p />', { "class" : "error text-danger", "text" : "We couldn’t find any results, please try adjusting your settings and searching again" });
                   if ($('.response-message p.error').length) {
                       $('.response-message p.error').remove();
                   }
                   $('.response-message').append(p);
               }
           });
       })
    }
})(jQuery);
function initMap() {
    if ($('#school-map__container').length) {
        var center_location = {
            lat: Number($('#form_lat').val()),
            lng: Number($('#form_lng').val())
        };

        var map = new google.maps.Map(document.getElementById('school-map__container'), {
            zoom: 8,
            center: center_location,
            styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
        });

        loadMapMarkers(map);
    }
}

function loadMapMarkers(map) {
    var bounds = new google.maps.LatLngBounds();

    var current_schools = $('.school-row').length;

    $('.school-row').each(function() {
        var school = $(this);

        var image = school.data('marker-image');

        var nonactive_icon = school.data('marker-image');

        var active_icon = school.data('marker-active-image');

        var marker_location = { lat: Number(school.data('lat')), lng: Number(school.data('long')) };

        var school_id = school.data('id');

        var marker = new google.maps.Marker({
            position: marker_location,
            map: map,
            schoold_id : school_id,
            active_icon : active_icon,
            nonactive_icon : nonactive_icon,
            icon: image
        });

        bounds.extend(marker.getPosition());

        marker.addListener('click', function() {
            if (previousMarker) {
                previousMarker.setIcon(previousMarker.nonactive_icon);

                previousMarker = false;
            }

            if (infoBubble) {
                infoBubble.close();
            }

            var html_content = cloneAndPopulateSchoolMapPopover(school_id);

            marker.setIcon(marker.active_icon);

            if (!previousMarker) {
                previousMarker = marker;
            }

            infoBubble = new InfoBubble({
                content: html_content,
                maxWidth:400,
                minHeight:250,
                maxHeight:250,
                padding: 0,
                backgroundColor: '#ffffff',
                borderRadius: 4,
                arrowSize: 10,
                borderWidth: 0,
                borderColor: '#2c2c2c',
                shadowStyle: 0,
                closeSrc:'/images/map-close.png'
            });

            infoBubble.open(map, marker);

            google.maps.event.addListener(infoBubble, 'closeclick', function() {
                if (previousMarker) {
                    previousMarker.setIcon(previousMarker.nonactive_icon);
                    previousMarker = false;
                }
            })
        });

    });
    //center the map to the geometric center of all markers
    map.setCenter(bounds.getCenter());
    if (current_schools > 1) {
        map.fitBounds(bounds);
        //remove one zoom level to ensure no marker is on the edge.
        map.setZoom(map.getZoom() - 1);
    }
    else
    {
        //var radius = $("#school_radius").val();
        map.setZoom(14);
    }
}

function cloneAndPopulateSchoolMapPopover(school_id)
{
    var html = $('.school-map__popup-wrapper').clone();

    var current_school = $('[data-id="' + school_id + '"]');
    $('.school-map__popover', html).addClass(current_school.data('map-popover-class'));
    $('.school-map__header h4', html).text(current_school.data('title'));
    $('.school-map__image', html).attr('src', current_school.data('school-image'));
    $('.stem-ranking', html).text(current_school.data('stem'));
    $('.ofsted-ranking', html).text(current_school.data('ofsted'));
    $('.change', html).text(current_school.data('change'));
    $('.type', html).text(current_school.data('type'));
    $('.gender', html).text(current_school.data('gender'));
    $('.stementryrate', html).text(current_school.data('stementryrate'));
    $('.stemaabrate', html).text(current_school.data('stemaabrate'));

    return html.html();
}