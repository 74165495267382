var header = (function (me) {

    me.init = function(){

	    //sticky menu on scroll
	    $(window).bind('scroll', function () {
	        if ($(window).scrollTop() > 50) {
	            $('.navbar-default').addClass('min');
	        } else {
	            $('.navbar-default').removeClass('min');
	        }
	    });

	    //mobile nav
	    var mobileNavExpanded = false;
	    $('.navbar-toggle').click(function(e) {
	        e.preventDefault();
	        if(mobileNavExpanded){
	            //contract
	            $('.navbar-toggle').addClass('collapsed');
	            $('#mobile_nav').fadeOut(function(){
	                $('body').removeClass('mobile_nav_expanded');
	                $(this).removeAttr("style");
	                mobileNavExpanded = false;
	            });
	        } else {
	            //expand
	            $('.navbar-toggle').removeClass('collapsed');
	            $('body').addClass('mobile_nav_expanded');
	            mobileNavExpanded = true;
	            $('#mobile_nav').fadeIn();
	        }
	    });


	    //magnetic line thingy
	    $('body:not(.mobile_nav_expanded) .navbar-main > li, body:not(.mobile_nav_expanded) .navbar-right > li').mouseenter(function(e) {
	        var offset = $(this).offset();
	        var width= $(this).outerWidth();
	        $('.magline').css('left',offset.left);
	        $('.magline').width(width);
	    }).mouseleave(function(e) {
	        $('.magline').css('left',0);
	        $('.magline').width(0);
	    });;


	    //close the mobile menu if browser resized large once open
	    //get true width of browser, matching how css gets width
	    function viewport() {
	        var e = window, a = 'inner';
	        if (!('innerWidth' in window )) {
	            a = 'client';
	            e = document.documentElement || document.body;
	        }
	        return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
	    }
	    //debounce timer
	    var resizeTimer;
	    $(window).on('resize', function(e) {
	      clearTimeout(resizeTimer);
	      resizeTimer = setTimeout(function() {
	        var windowWidth = viewport().width;
	        if(windowWidth > 750){
	                //reset the menu
	                $('.navbar-toggle').addClass('collapsed');
	                $('body').removeClass('mobile_nav_expanded');
	                $('#mobile_nav').removeAttr("style");
	                mobileNavExpanded = false;
	        }
	      }, 100);
	    });



    };

	var jqxhr = {abort: function () {}};

    me.updateFavouritesBadge = function(){
		jqxhr.abort();
    	//https://mdbootstrap.com/css/animations/
	    var effect = 'bounce';

	    $('[data-favourites-badge]').removeClass('animated '+effect);
	    $('[data-favourites-badge]').addClass('hidden');
	    $('[data-favourites-badge-loading]').removeClass('hidden');


    	jqxhr = $.ajax({
    		url: '/api/favourites/get',
    	})
    	.done(function(data) {
    		//console.log("success");
    		updateBadge(data);
    	})
    	.fail(function(data) {
    		//console.log("error");
    	})
    	.always(function(data) {
    		//console.log("complete");
    	});

    	function updateBadge(data){
    		try {
	    		var count = data.length;
		    	$('[data-favourites-menu-item]').removeClass('disabled');
			    $('[data-favourites-badge-loading]').addClass('hidden');
			    $('[data-favourites-badge]').removeClass('hidden');
		    	$('[data-favourites-badge]').text(count);
				    
	    		if(count>0){
		    		$('[data-favourites-badge]').addClass('animated '+effect);
		    	} else {
		    		$('[data-favourites-menu-item]').addClass('disabled');
		    		//$('[data-favourites-badge]').addClass('disabled');
		    	}
	    	} catch(error){
	    		console.log('error updating favourites badge', error);
	    	}
    	}
    }





    return me;
}(header || {}));
