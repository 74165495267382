$(function() {
    var headerHeight = $('body > nav').height();
    $('a[href*="#"]:not([href="#"]):not([data-toggle])').click(function() {
        if ($(this).hasClass('carousel-control')) {
            return true;
        }
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - headerHeight
                }, 1000, "easeInOutQuart");
                return false;
            }
        }
    });
});