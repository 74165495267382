(function($){
    $('.load-more a').click(function(e) {
        e.preventDefault();
        $('.hidden-jobrow').removeClass('hidden-jobrow');
        $('.load-more').hide();
    });
    $('.a-level-choice').change(function(e){
        e.preventDefault();

        var headerHeight = $('body > nav').height();

        var current_a_level = $(this).val();

        if ($('option:selected', this).hasClass('hidden-element') && $('.hidden-jobrow').length) {
            $('.load-more a').click();
        }

        $('html, body').animate({
            scrollTop: $('#' + current_a_level).offset().top - headerHeight
        }, 1000, "easeInOutQuart");
    })
})(jQuery);