var job = (function (me) {
    me.init = function(){
     	if(!$('body').hasClass('route-job')) return;

		var jqxhr = {abort: function () {}};

		$('body').on('click', '[data-inaccurate-match]', function(e){
			e.preventDefault();
			var job_id = $(this).data('job-id');
            updateInaccurateMatch(job_id);
		});

	    $('body').on('click','[data-favourite-action]',function(e) {
	    	e.preventDefault();
	    	var action = $(this).attr('data-favourite-action');
	    	var id = $(this).attr('data-id');
	    	element = $('[data-favourite-action]');

	    	if(action=='add'){
	    		doAction('add',id,element);
	    	} else if(action=='remove'){
	    		doAction('remove',id,element);
	    	}
	    });

	    var isFavouriteClass = 'fa-heart';
	    var notFavouriteClass = 'fa-heart-o';
	    var loadingClass = 'fa-refresh fa-spin';
    	var oldClass, newClass, newAction;

	    function doAction(type,id,element){
	    	if(!parseInt(id)) return false;
	    	//show loading indicator
	    	element.removeAttr('data-favourite-action').children('span').css('opacity',0.25);
	    	element.children('i').removeClass(isFavouriteClass).removeClass(notFavouriteClass).addClass(loadingClass);
	    	//do ajax
			jqxhr.abort();
	    	jqxhr = $.ajax({
	    		url: '/api/favourites/'+type+'/'+id,
	    	})
	    	.done(function(data) {
	    		//console.log("success",data);
	    		if(data.status=='success'){
	    			updateFavouriteIcon(type,element);
	    		} else {
	    			if(type == 'add') type='remove';//if error removing, prob already removed, make it addable
	    			updateFavouriteIcon(type,element);
	    		}
	    		if (data.status == 'success' && type == 'add' && $('.feedback-modal').length) {
                    setTimeout(function () {
                        $('.feedback-modal').modal('show');
                    }, 3000);
				}
	    	})
	    	.fail(function(data) {
	    		//console.log("error",data);
	    	})
	    	.always(function(data) {
	    		//console.log("complete",data);
	    	});
	    }

	    //callback - change to add / remove
	    function updateFavouriteIcon(type,element){
	    	if(type=='remove'){
	    		oldClass = isFavouriteClass;
	    		newClass = notFavouriteClass;
	    		newAction = 'add';
	    		newText = 'Add to favourites';
	    		$('[data-job-info-is-favourite]').fadeOut(500,function(){
	    			$('[data-job-info-is-favourite]').addClass('hidden');
	    			$('[data-job-info-not-favourite]').removeClass('hidden').fadeIn();
	    		});
	    	} else {
	    		oldClass = notFavouriteClass;
	    		newClass = isFavouriteClass;
	    		newAction = 'remove';
	    		newText = 'Remove from favourites';
	    		$('[data-job-info-not-favourite]').fadeOut(500,function(){
	    			$('[data-job-info-not-favourite]').addClass('hidden');
	    			$('[data-job-info-is-favourite]').removeClass('hidden').fadeIn();
	    		});
	    	}
	    	element.attr('data-favourite-action',newAction).children('span').text(newText).css('opacity',1);
	    	element.children('i').removeClass(loadingClass).addClass(newClass);

	    	header.updateFavouritesBadge();
	    }
    };
    return me;
}(job || {}));
