//this fixes the navbar issue on iOS where you scroll up and navbar hides, leaving an empty hole.
$(function(){
  var $w = $(window),
      $background = $('.cover_image, .cover_image_overlay');
  // Fix background image jump on mobile
  if ((/Android|iPhone|iPad|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
    $background.css({'top': 'auto', 'bottom': 0});
    $w.resize(sizeBackground);
    sizeBackground();
  }
  function sizeBackground() {
     $background.height(screen.height);
  }
});