$(function() {
    WebFont.load({
        google: {
            //families: ['Open+Sans:400,700:latin-ext']
            families: ['Montserrat:400,700:latin-ext']
        }
    });

    //social share buttons
    //https://github.com/ozdemirburak/jquery-floating-social-share
    // $("#social_buttons").floatingSocialShare({
    //     buttons: ["facebook","twitter"],
    //     text: "Share with ",
    //     title: document.title,
    //     url: window.location.href,
    //     description: "Your Life Job Matching Tool - search and match jobs to your personality and see what A Levels you would need to take.",
    //     counter: false
    // });

    //load application modules
    header.init();
    index.init();
    login.init();
    jobs.init();
    job.init();
    favourites.init();
    swiper.init();
    feedback.init();
    personality.init();
    

    //much animate, such wow
    new WOW().init();

    //fastclick
    $(function() {
        FastClick.attach(document.body);
    });

    //enable any popovers
    $('[data-toggle="popover"]').popover({
        trigger: 'focus'
    });
    //incase any href left in
    $('[data-toggle="popover"]').click(function(e) {
        e.preventDefault();
    });

        
});



function updateInaccurateMatch(id)
{
    var data = {id: id};
    
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    $.ajax({
        type: "POST",
        url: "/inaccurate-match/" + id,
        data: data,
        success: function(data){
            $('#inaccurateMatchModel').modal('show');
        },
        error: function(data){
            console.log('failure');
        }
    })
}

$( document ).ready(function(){

    var footerEl = $('#footer');
    var floatingFooterEl = $('#fixed-footer');

    $( window ).scroll( function() {
        var scrollPos = $( window ).height() + $( window ).scrollTop();

        if( scrollPos >= $( document ).height() - footerEl.height() ) {
            floatingFooterEl.css('bottom', ( scrollPos - $( document ).height() ) + footerEl.innerHeight() );
        }else{
            floatingFooterEl.css('bottom',0);
        }
    });

    $( window ).scroll();
});



//For some reason, elixir seems to miss this file when watching / compiling. Like its compiling before it does the live reload.
//just reload the page and it'll find the js