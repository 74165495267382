var personality = (function (me) {

  me.init = function(){

    function data ( element, key ) {
      return element.getAttribute('data-' + key);   
    }

    var resultElement = document.getElementsByClassName('range-value'),
        range_wrapper = document.getElementsByClassName('slider-range__wrapper'),
        sliders = document.getElementsByClassName('slider-range');

      Array.prototype.forEach.call(sliders, function(slider) {

          var slider_input = $(slider).closest('.slider-range__wrapper').next('.range-value');

          noUiSlider.create(slider, {
              start: [0],
              connect: true,
              tooltips: false,
              step: 1,
              range: {
                  'min': 0,
                  'max': 100
              },
              format: {
                  to: function (value) {
                      return value + '';
                  },
                  from: function (value) {
                      return value.replace('', '');
                  }
              },
          });

          slider.noUiSlider.on('update', function( values, handle ) {
              slider_input.val(values[handle]);
          });
      });
  };

  return me;
}(personality || {}));
